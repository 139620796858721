.container {
    padding: 45px;
}


.image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.container-image-footer.image-container {
    width: 50px;
    height: 50px;
}

.logo-header-footer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.footer-content {
    background: #0d0616;
    margin-top: 50px;
}

.link-container.footer-li-links {
    padding: 10px 0px;
    color: gray;
}

.content-title {
    padding: 10px 0;
}

.container.footer-box-info {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.content-copyright-box {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    border-top: solid 1px #ffffff1f;
}

.footer-copyright {
    color: gray;
}