@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz@9..40&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
    margin: 0;
    background: #160f2e;
}

* {
    font-family: 'Ubuntu', sans-serif;
    color: #ffffff;
}

img {
    width: 100%;
}

.inter-font {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "slnt" 0;
}

.bold-text {
    font-weight: 700;
}

a._links {
    text-decoration: none;
}

.links {
    text-decoration: none;
    color: unset;
}

* {
    box-sizing: border-box;
}