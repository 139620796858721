.content-blog-updates {
    overflow: hidden;
    border-radius: 8px;
    background-color: #ffffff;
    transition: scale 0.5s ease-in-out;
}

.content-blog-updates:hover {
    scale: 1.05;
}

.container-title-blog-update {
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
}

.container-title-box {
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1rem;
}

.black-text {
    color: #000000;
}

.container-title-index {
    color: #000000;
}

.container-description-update,
.box-container-info {
    padding: 5px 15px;
}

.date-box-x {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.content-date-x {
    margin: 0px 5px;
}

.content-info-s-x-blog-cover {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.bold-text {
    font-weight: 700;
}

.gray-color {
    color: gray;
}