@media screen and (max-width: 768px) {
    div._main_layout_cbox.fade-in {
        font-size: 2.5rem;
    }

    div._home_ly_w {
        margin: 0px 30px;
    }

    div._container_main_layout {
        flex-direction: column;
    }

    div._content_p_main_box {
        text-align: justify;
    }

    div._container_build_section {
        flex-direction: column;
    }

    div._img_container_build_box {
        width: 70%;
    }

    div._container_p_box {
        width: 80%;
        margin-top: 10px;
    }

    div._title_box_build {
        font-size: 3rem;
    }

    div._build_data_ds {
        flex-direction: column;
    }

    div.container.container-footer-content {
        padding: 10px;
    }

    div.navbar-icon-responsive {
        display: block;
    }

    div._navbar_ul {
        position: fixed;
        flex-direction: column;
        z-index: 9999;
        width: 100%;
        height: 100%;
        right: -100%;
        background: rgba(0, 0, 0, 0.61);
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        backdrop-filter: blur(8px);
        transition: right 0.5s ease-in-out;
    }

    div.content-blog-updates {
        width: 100%;
        height: 100%;
    }

    div._navbar_ul.menu-active {
        right: 0;
    }

    div._ul_container {
        flex-direction: column;
        text-align: center;
        justify-content: space-around;
        position: relative;
        width: 50%;
    }

    div.container.footer-box-info {
        display: grid;
        justify-content: flex-start;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-row-gap: 20px;
        grid-column-gap: 60px;
    }

    div.date-box-x {
        font-size: .8rem;
    }

    div.container-title-box {
        font-size: 0.8rem;
    }

    div.container.content-blog-cover {
        display: grid;
        justify-content: flex-start;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-row-gap: 10px;
        grid-column-gap: 10px;
    }

    div.update-content.gray-color.bold-text {
        font-size: .8rem;
    }

    div.content-title.general-info {
        text-align: center;
    }

    div.SS_Wwwsawe {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: justify;
    }

}