.container-tos-content-element {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 800px;
}

.container.content-terms-of-use {
    display: flex;
    align-items: center;
    justify-content: center;
}

.update-container-sxa {
    text-align: center;
}

.content-terms-x-text {
    margin: 20px 5px;
}

.container-text-elements {
    text-align: justify;
    font-size: 1.2rem;
    font-weight: 400;
}

.content-element-text-xs {
    margin: 30px 0px;
}

.text-li-content {
    display: flex;
    align-items: flex-start;
}

.number-text-index {
    margin-right: 10px;
}