._main_layout_cbox {
    font-family: 'DM Sans', sans-serif;
    font-size: calc(6rem);
}

._container_main_layout {
    display: flex;
    align-items: center;
}

._content_p_main_box {
    font-family: 'Inter', sans-serif;
}

._img_logo_main_sa {
    width: calc(80%);
}

img._main_logo {
    width: calc(100%);
}

span._chain_p_s {
    color: #5eb81b;
    padding-right: 5px;
}

span._chain_p_d {
    color: #4265a9;
    padding-right: 5px;
}

span._chain_p_b {
    color: #732b9d;
    padding-right: 5px;
}

._content_p_main_box {
    font-size: calc(1.1rem);
    font-weight: 300;
    padding: 30px 0px;
}

._button_built {
    display: flex;
    align-items: center;
    background: #9170f3;
    width: fit-content;
    padding: 10px;
    border-radius: 15px;
    /*     transition: scale 0.5s ease-in-out; */
    cursor: pointer;
}

/* ._button_built:hover {
    scale: 1.1;
} */

a._btn_ {
    text-decoration: none;
}

._button_box_built {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
}

.fade-in {
    -webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

._j_sw_main_box {
    width: calc(80%);
}

._container_build_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

._p_content_s {
    width: calc(70%);
    padding: 10px 0px;
    font-family: 'Inter', sans-serif;
    color: #d1d1d1;
}

._img_container_build_box {
    overflow: hidden;
    border-radius: 15px;
    width: calc(30%);
}

img._img_content_build {
    width: calc(100%);
    transition: scale 0.5s ease-in-out;
}

img._img_content_build:hover {
    scale: 1.1;
}


._container_button {
    display: flex;
}

._container_p_box {
    width: calc(60%);
}

._title_box_build {
    font-family: 'DM Sans', sans-serif;
    font-size: calc(6rem);
    font-weight: bold;
}

._container_p_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, .08);
    border: 1px solid rgba(255, 255, 255, .12);
    border-radius: 12px;
    backdrop-filter: blur(8px);
    padding: 15px;
    transition: all 0.5s ease-in-out;
}

._container_p_box:hover {
    background-color: rgba(255, 255, 255, 0.253);

}

.rotate-in-center {
    -webkit-animation: rotate-in-center 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: rotate-in-center 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes rotate-in-center {
    0% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}

@keyframes rotate-in-center {
    0% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }
}


._build_data_ds {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.S_ww_Rwas {
    width: calc(40%);
}


.SS_Wwwsawe {
    width: calc(60%);
}

img._ssw_eeA {
    width: calc(100%);
}