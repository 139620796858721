.container-images {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 15px;
    transition: scale 0.5s ease-in-out;
}

.social-page {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-images:hover {
    scale: 1.1;
}

.disable-link {
    cursor: not-allowed;
}