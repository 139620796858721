._navbar_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
    /* Se movió aquí para evitar duplicación */
}

._navbar_logo_container {
    display: flex;
    align-items: center;
}

._navbar_ul {
    width: 60%;
}

._logo_navbar {
    width: 80px;
}

img._logo {
    width: 100%;
}

._ul_container {
    display: flex;
}

._li_navbar {
    font-size: 1.1rem;
}

._text_container_s {
    padding: 10px;
    cursor: pointer;
}

._ecosystem_show_d {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.61);
    backdrop-filter: blur(8px);
    border-radius: 12px;
    height: 100%;
    width: 100%;
    max-height: 300px;
    max-width: 300px;
    overflow: hidden;
}

._ul_ec_ {
    display: flex;
    align-items: baseline;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
}

a._links {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

._image_link {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
}

._content_sslw_Ett {
    padding: 5px 0;
    width: 100%;
}

a._link_refer_navbar {
    padding: 10px 0;
    transition: background-color 0.5s ease-in-out;
    border-radius: 8px;
    width: 100%;
    overflow: hidden;
}

a._link_refer_navbar:hover {
    background-color: rgba(172, 172, 172, 0.61);
    border: solid 0.1px rgba(255, 255, 255, 0.404);
}

.image-navbar-container-ss {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.close-button-swe {
    position: fixed;
    right: -100%;
    transition: right 0.5s ease-in-out;
    z-index: 9999;
    cursor: pointer;
}

.container.content-blog-cover {
    padding: 20px;
}

.close-button-swe.menu-active {
    right: 10%;
}

.navbar-icon-responsive {
    display: none;
}

img._icon_link {
    width: 100%;
}

._links_container_ {
    display: flex;
    width: 0%;
    height: 0;
    max-height: 300px;
    max-width: 300px;
    position: absolute;
    z-index: 9999;
    padding: 10px 0px;
    transition: all 0.5s ease-in-out;
}


._links_container_oos_w {
    width: 100%;
    height: 300px;
}

._p_ec_s {
    font-size: 12px;
}

._tittle_link_box {
    text-align: left;
    padding: 0 5px;
    width: 100%;
}

._not_enabled_ssui_E {
    filter: brightness(0.5);
    cursor: not-allowed;
}