img._img_desk_bck {
    object-fit: cover;
    width: 100%;
    height: 100%;
    min-height: 700px;
    mask-image: linear-gradient(to top, transparent 0%, black 40%);
}

._logo_name_navbar {
    font-size: calc(1.5rem);
}

._home_ly_w {
    margin: 0 80px;
}