.header-article-image {
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-box-content {
    width: 600px;
    height: auto;
    text-align: justify;
    font-size: 1rem;
}

.text-box.info-container {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-title-secundary {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.content-title-s2.content-blog-title-all {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.content-title-s2 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.blog-content-box {
    display: flex;
    align-items: center;
    justify-content: center;
}

.blog-content-box {
    margin: 15px;
}

.container.content-blog-cover {
    display: grid;
    justify-content: flex-start;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: 10px;
    grid-column-gap: 10px;
}

.content-blog-cover {
    display: flex;
    align-items: center;
    justify-content: center;
}

.blog-content-box.content-grid-blog {
    display: grid;
    justify-content: flex-end;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-row-gap: 10px;
    grid-column-gap: 10px;
}

.title-box-ss {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pointer-text-box-ss {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ss-content-block-text {
    width: 600px;
}

.text-li-items {
    text-decoration: underline;
}

.li-items {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.container-images-dot {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
}

.links-box-s-content {
    width: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title-box-ss.center-flex {
    width: 600px;
    text-align: center;
    font-size: 1.2rem;
}

.links-container-box {
    display: flex;
    align-items: center;
    justify-content: center;
}

.links.links-blog {
    margin: 10px;
}